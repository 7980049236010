import React, { useEffect } from 'react';
import styles from './AdSlot.module.css';

const AdSlot = ({ position, adId, format, width, height, maxHeight }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error('AdSense error:', err);
    }
  }, []);

  const adStyle = { display: 'block' };
  if (width) adStyle.width = width;
  if (height) adStyle.height = height;
  if (maxHeight) adStyle.maxHeight = maxHeight;

  const adProps = {
    className: "adsbygoogle",
    style: adStyle,
    'data-ad-client': "ca-pub-6954832650926400",
    'data-ad-slot': adId,
  };

  if (format === 'auto') {
    adProps['data-ad-format'] = 'auto';
  }

  if (format === 'auto') {
    adProps['data-ad-format'] = 'auto';
  }

  return (
    <div className={`${styles.adSlot} ${styles[position]}`}>
      <ins {...adProps} />
    </div>
  );
};

export default AdSlot;